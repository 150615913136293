import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
// import BackgroundImage from "gatsby-background-image"

import theme from "../themes/theme"

import { PageLayout } from "../components/PageLayout"

import {
  HeroGrid,
  HeroSection,
  HeroHeadline,
  HeroSubHead,
  // HeroExtra,
  // HeroButton,
} from "../components/HeroSection"

import { ButtonPrimary, ButtonSecondary } from "../components/Buttons"

import {
  RowSection,
  TwoColumnSection,
  TwoColumnSectionLeft,
  TwoColumnSectionRight,
  OneColumnSection,
} from "../components/SectionBlocks"

import {
  TestimonialWrapper,
  TestimonialImage,
  TestimonialName,
  TestimonialTitle,
  TestimonialMessage,
} from "../components/Testimonial"

import SEO from "../components/seo"

const CoachMitchJobson = ({ data, location }) => {
  return (
    <PageLayout>
      <SEO
        title="Coach - Mitch Jobson"
        ogImage={data?.socialImage?.childImageSharp?.fixed.src}
        description="Coaching professionals to look great, get stronger & play harder"
        url={location.origin + location.pathname}
      />
      <HeroGrid
        picUrl={data?.heroImage?.childImageSharp?.fluid}
        bgX="0%"
        // mobileColor={theme.colors.background}
        style={{ marginLeft: "1rem", marginRight: "1rem" }}>
        <HeroSection>
          <div className="max-w-md flex flex-col items-center md:items-start justify-center md:justify-start gap-y-4 ml-5 py-28">
            <div className="text-6xl text-primaryOrangeNormal text-center md:text-left font-bold">
              Mitch Jobson
            </div>
            <div className="text-4xl text-dark1 text-center md:text-left font-bold">Head Coach</div>
            <div className="text-3xl text-dark1 text-center md:text-left font-ptsans">
              I'm the head coach for the Liftology Project and lead our team of specialist coaches
              as well as training people just like you.
            </div>
            <ButtonPrimary to="/free-assessment?coach=mitch" style={{ boxShadow: "none" }}>
              Book Free Assessment
            </ButtonPrimary>
          </div>
          {/* <HeroHeadline>
            <h1
              className="text-2xl"
              style={{
                color: theme.colors.primaryOrangeNormal,
                textAlign: "center",
                lineHeight: theme.spacings.large,
                marginBottom: "0px",
              }}>
              Mitch Jobson
            </h1>
          </HeroHeadline>
          <HeroSubHead className="flex flex-col items-center">
            <h2
              style={{
                textAlign: "center",
                marginTop: "0px",
                lineHeight: theme.spacings.large,
                color: theme.colors.light1,
              }}>
              Head Coach
            </h2>
            <p className="max-w-sm" style={{ textAlign: "center", color: theme.colors.light1 }}>
              I'm the head coach for the Liftology Project and lead our team of specialist coaches
              as well as training people just like you.
            </p>
            <ButtonPrimary to="/free-assessment" style={{ boxShadow: "none" }}>
              Book Free Assessment
            </ButtonPrimary>
          </HeroSubHead> */}
        </HeroSection>
      </HeroGrid>

      <RowSection rowMargin="10%">
        <OneColumnSection
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}>
          <h2 style={{ color: theme.colors.primaryOrangeNormal }}>About Me</h2>
        </OneColumnSection>
      </RowSection>
      <RowSection rowMargin="10%" style={{ marginBottom: "15px" }}>
        <TwoColumnSection
          columnLeftTop="true"
          columnLeftWidth="40%"
          columnRightWidth="60%"
          sideMargin="0%"
          style={{}}
          className="md:gap-x-6">
          {/* <TwoColumnSectionLeft
            alignItems="center"
            justifyContent="center"
            style={{ alignItems: "center" }}>
            <Img
              fluid={data?.mitchHeadshot?.childImageSharp?.fluid}
              alt="Mitch Jobson"
              style={{ width: "300px", borderRadius: "100%", objectFill: "cover" }}
            />
          </TwoColumnSectionLeft> */}
          <TwoColumnSectionLeft
            alignItems="left"
            justifyContent="center"
            style={{ alignItems: "center" }}>
            <Img
              fluid={data?.mitchHeadshotBunker?.childImageSharp?.fluid}
              alt="Mitch Jobson"
              className="w-full"
            />
          </TwoColumnSectionLeft>
          <TwoColumnSectionRight alignItems="flex-start" justifyContent="flex-start">
            <HeroHeadline>
              <h3 className="text-primaryBlueNormal text-left ">
                Why you should hire me as your coach
              </h3>
              <p>
                I am a fully qualified coach with more than 15 years of experience training people
                to achieve their goals
              </p>
              <p>
                With certifications in strength, conditioning, boxing, and Tae Kwon Do, I can help
                you get stronger, fitter, transform your body to lose those unwanted kilos, and be
                more flexible.
              </p>
              <p>
                You may not believe it, but 16 years ago I used to be just like you. Yes, I am
                serious. Just. Like. You.
              </p>
              <p>
                After being the sporty kid, I left school and didn't look after myself as much as I
                know i should have. I put on a heap of weight
              </p>
              <p>
                Being unhappy, I made a decision to change things. I started eating right and
                working out with a great trainer. I've since lost, and kept off, over 20kg.
              </p>
              <p>
                Since then I've studied, and event taught, tae kwan do and gymnastics, and have been
                a successful personal trainer for over 14 years helping{" "}
                <strong>more than 5,000</strong> clients achieve their goals.
              </p>
              <p>
                I'd love to learn more about what you're looking to achieve and help you get there
                too.
              </p>
              <p>
                Click on the <strong>Start Today</strong> button for a{" "}
                <span style={{ color: theme.colors.primaryOrangeNormal }}>
                  no obligation free assessment
                </span>{" "}
                to see how we can help.
              </p>
              <ButtonSecondary to="/free-assessment?coach=mitch">Start Today</ButtonSecondary>
            </HeroHeadline>
          </TwoColumnSectionRight>
        </TwoColumnSection>
      </RowSection>
      <RowSection rowMargin="10%">
        <OneColumnSection
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}>
          <h2 style={{ color: theme.colors.primaryOrangeNormal, textAlign: "center" }}>
            What My Clients Say About Training With Me
          </h2>
        </OneColumnSection>
      </RowSection>
      <div className="flex flex-wrap flex-row justify-center">
        <TestimonialWrapper>
          <TestimonialImage
            width="120px"
            height="120px"
            alt="Andrew Hatfield"
            src={data?.headshotAndrewHatfield?.childImageSharp?.gatsbyImageData}
          />
          {/* <Img fixed={data?.headshotAndrewHatfield?.childImageSharp.fixed} alt="Andrew" /> */}
          <TestimonialName>Andrew Hatfield</TestimonialName>
          <TestimonialTitle>Marketing</TestimonialTitle>
          <TestimonialMessage>
            After many years of running, and playing tennis and soccer, I ended up injuring my back.
            Sneezing was often dangerous enough to put me out of action for days. Working with Mitch
            for just over a year, I now no longer fear sneezing and can easily keep up with my kids,
            move furniture, and lift more than my bodyweight!
          </TestimonialMessage>
        </TestimonialWrapper>
        <TestimonialWrapper>
          <TestimonialImage
            width="120px"
            height="120px"
            alt="Byron Paine"
            src={data?.headshotByronPaine?.childImageSharp?.gatsbyImageData}
          />

          <TestimonialName>Byron Paine</TestimonialName>
          <TestimonialTitle>Retail</TestimonialTitle>
          <TestimonialMessage>
            Mitch has helped me realise progress comes in all shapes and forms, and that it’s not
            going to be an easy task hut that it can be done. Mitch has shown incredible dedication
            to myself and his other clients which drives our thirst for strength even more.
          </TestimonialMessage>
        </TestimonialWrapper>
        <TestimonialWrapper>
          <TestimonialImage
            width="120px"
            height="120px"
            alt="Craig MacDonald"
            src={data?.headshotCraigMacDonald?.childImageSharp?.gatsbyImageData}
          />

          <TestimonialName>Craig MacDonald</TestimonialName>
          <TestimonialTitle>Property</TestimonialTitle>
          <TestimonialMessage>
            Mitch isn't your bog standard trainer, and I can say that having trained with a couple
            before him. Mitch is your coach. Not only does he know his craft well, he is always
            learning and staying up to date. Attentive and focused, picking up on small but
            important incremental improvements.
          </TestimonialMessage>
        </TestimonialWrapper>
        {/* </Testimonial3ColumnWrapper> */}
        {/* second set */}
        {/* <Testimonial3ColumnWrapper> */}
        <TestimonialWrapper>
          <TestimonialImage
            width="120px"
            height="120px"
            alt="Michel Hanson"
            src={data?.headshotMichaelHanson?.childImageSharp?.gatsbyImageData}
          />
          <TestimonialName>Michael Hanson</TestimonialName>
          <TestimonialTitle>Media</TestimonialTitle>
          <TestimonialMessage>
            Absolutely an amazing PT, listen to him! My stress levels dropped and my confidence and
            overall health went through the roof.. I would recommend him to anyone! Get in with him
            before he is fully booked!
          </TestimonialMessage>
        </TestimonialWrapper>
        <TestimonialWrapper>
          <TestimonialImage
            width="120px"
            height="120px"
            alt="Dallas Allen"
            src={data?.headshotDallasAllen?.childImageSharp?.gatsbyImageData}
          />

          <TestimonialName>Dallas Allen</TestimonialName>
          <TestimonialTitle>Beauty</TestimonialTitle>
          <TestimonialMessage>
            Excellent one on one PT experience, fully understands the body and how to give you the
            best and right experience. Takes the time to really look at your form and correct it for
            the right work outs. My results have been amazing and my confidence working out on my
            own even higher now.
          </TestimonialMessage>
        </TestimonialWrapper>
        <TestimonialWrapper>
          <TestimonialImage
            width="120px"
            height="120px"
            alt="Junyi Zhou"
            src={data?.headshotJunyiZhou?.childImageSharp?.gatsbyImageData}
          />

          <TestimonialName>Junyi Zhou</TestimonialName>
          <TestimonialTitle>Construction</TestimonialTitle>
          <TestimonialMessage>
            Mitch is an excellent PT who has a great sense of humor! After training with him for
            over a year, I can see a big change in my energy levels and body. If you are skinny and
            want more curves, look no further;)
          </TestimonialMessage>
        </TestimonialWrapper>
        {/* </Testimonial3ColumnWrapper> */}
      </div>

      <RowSection rowMargin="10%" style={{ marginBottom: "50px" }}>
        <OneColumnSection
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            flexGrow: "1",
          }}>
          <ButtonPrimary to="/free-assessment?coach=mitch" style={{ flexGrow: "1" }}>
            Get a Free Assessment
          </ButtonPrimary>
        </OneColumnSection>
      </RowSection>
    </PageLayout>
  )
}

export default CoachMitchJobson

// Mitch - Jobson - White - Singlet - 497x885.png

// heroImage: file(absolutePath: { regex: "/mitch-bench-client-1680x945.jpg/" }) {
//   childImageSharp {
//     fixed(width: 497, height: 885, quality: 95) {
//       ...GatsbyImageSharpFixed_withWebp
//     }
//     fluid(maxWidth: 500, quality: 95) {
//       ...GatsbyImageSharpFluid_withWebp
//       ...GatsbyImageSharpFluidLimitPresentationSize
//     }
//   }
// }

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }

    heroImage: file(absolutePath: { regex: "/mitch-bench-client-gradient.jpg/" }) {
      childImageSharp {
        fixed(width: 497, height: 885, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1680, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    mitchHeadshotBunker: file(absolutePath: { regex: "/mitch-bunker-headshot-trans.png/" }) {
      childImageSharp {
        fixed(width: 497, height: 885, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxHeight: 700, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    mitchHeadshot: file(
      absolutePath: { regex: "/mitch-standing-arms-crossed-blue-20200506_152253-2-778x778.jpg/" }
    ) {
      childImageSharp {
        fixed(width: 497, height: 885, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 500, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    headshotAndrewHatfield: file(absolutePath: { regex: "/andrewhatfield-170x170.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 120, height: 120, formats: [AVIF, AUTO, WEBP])
      }
    }

    headshotByronPaine: file(absolutePath: { regex: "/byron-170x170.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 120, height: 120, formats: [AVIF, AUTO, WEBP])
      }
    }

    headshotCraigMacDonald: file(absolutePath: { regex: "/craig-macdonald-170x170.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 120, height: 120, formats: [AVIF, AUTO, WEBP])
      }
    }

    headshotMichaelHanson: file(absolutePath: { regex: "/michael-hanson-170x170.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 120, height: 120, formats: [AVIF, AUTO, WEBP])
      }
    }

    headshotDallasAllen: file(absolutePath: { regex: "/dallas-allen-170x170.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 120, height: 120, formats: [AVIF, AUTO, WEBP])
      }
    }

    headshotJunyiZhou: file(absolutePath: { regex: "/junyi-zhou-170x170.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 120, height: 120, formats: [AVIF, AUTO, WEBP])
      }
    }

    socialImage: file(
      absolutePath: { regex: "/mitch-alex-bunker-wall-logo-social-1200x628.jpg/" }
    ) {
      childImageSharp {
        fixed(width: 1200, height: 628, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
